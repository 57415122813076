<template>
  <v-row class="ma-2" justify="center">
    <v-col cols="12">
      <v-card
          class="mx-auto text--white"
          color="#fafafa"
          max-width="700"
      >
        <v-card-title>
          <p class="text-h4 text--primary">
            Welcome to Rabbi IT Firm Admin v{{appVersion}}
          </p>
        </v-card-title>

        <v-card-text class="text-h5 font-weight-bold">
          "Rabbiitfirm: Advances in these fields: you'll be capable to rapidly publish informational and promotional articles/contents on most surveyed websites. Use professional guestposing and outreach services to acquire high-authority backlinks from trustworthy platforms and classify the site to be gathered."
        </v-card-text>

        <v-card-actions>
          <v-list-item class="grow">
            <v-row
                align="center"
                justify="end"
            >
              <social-icon v-for="(item, key) in socialData" :link="item.link" :icon="item.icon" :key="key"/>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SocialIcon from "../components/buttons/SocialIcon";
export default {
  name: "Home",
  components: {SocialIcon},
  data() {
    return {
      socialData: [
        {
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/rabbiitfirm/'
        },
        {
          icon: 'mdi-youtube',
          link: '#'
        },
        {
          icon: 'mdi-linkedin',
          link: '#'
        },
        {
          icon: 'mdi-twitter',
          link: '#'
        }
      ],
      appVersion: localStorage.getItem('app-version')
    }
  }
}
</script>

